import React, { useEffect, useState } from 'react';
import { MdHeadsetMic } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import logo from '../assets/images/logo.png';

const Connection = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the player is already connected
    const checkPlayerConnection = async () => {
      try {
        const response = await axios.get('https://custom.hibry.net/check-session', { withCredentials: true });
        if (response.data.connected) {
          navigate('/prefix');
        }
      } catch (error) {
        console.error('Error checking player connection:', error);
      }
    };

    checkPlayerConnection();
  }, [navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (username === '' && password === '') {
      setErrorMessage('Veuillez renseigner tous les champs.');
    } else if (username === '') {
      setErrorMessage('Veuillez renseigner un pseudo.');
    } else if (password === '') {
      setErrorMessage('Veuillez renseigner un mot de passe.');
    } else {
      setErrorMessage('');
      try {
        const response = await axios.get('https://custom.hibry.net/login', { username, password }, { withCredentials: true });
        Cookies.set('username', username, { expires: 1 }); // Set the username in a cookie
        navigate('/prefix');
      } catch (error) {
        setErrorMessage(error.response.data);
      }
    }
  };

  return (
    <div className="min-h-screen bg-black flex flex-col">
      {/* Header with subtle gradient */}
      <header className="bg-gradient-to-r from-black via-black to-cyan-950 p-4 md:p-6 border-b border-cyan-900/30" style={{ height: '100px' }}>
        <div className="max-w-6xl mx-auto flex justify-between items-center h-full relative">
          <div className="absolute left-1/2 transform -translate-x-1/2">
            <a href="#">
              <img src={logo} alt="Logo" className="h-16 md:h-20" />
            </a>
          </div>
          <div className="ml-auto flex items-center border border-cyan-500 rounded-md p-2">
            <MdHeadsetMic className="text-cyan-500 h-6 w-6" />
            <a href="#" className="hover:text-cyan-300 transition-colors ml-2 font-semibold text-white">
              SUPPORT
            </a>
          </div>
        </div>
      </header>

      <main className="flex-1 flex items-center justify-center px-4 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-black via-black to-cyan-950">
        <div className="w-full max-w-md">
          <div className="bg-black/50 backdrop-blur-sm border border-cyan-900/30 rounded-lg shadow-2xl shadow-cyan-900/50 p-6 md:p-8">
            <div className="text-center mb-6 md:mb-8">
              <h1 className="text-2xl md:text-3xl font-bold text-white mb-2">CONNEXION</h1>
              <p className="text-cyan-400">Veuillez entrer vos identifiants pour vous connecter.</p>
            </div>

            <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
              <div>
                <label className="block text-gray-300 text-sm font-medium mb-2">Pseudo</label>
                <input
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="w-full bg-black/50 text-white pl-4 pr-4 py-2 md:py-3 rounded-md border border-cyan-900/50 focus:border-cyan-400 focus:ring-1 focus:ring-cyan-400 focus:outline-none transition-colors"
                  placeholder="Entrez votre pseudo"
                />
              </div>
              <div>
                <label className="block text-gray-300 text-sm font-medium mb-2">Mot de passe</label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full bg-black/50 text-white pl-4 pr-4 py-2 md:py-3 rounded-md border border-cyan-900/50 focus:border-cyan-400 focus:ring-1 focus:ring-cyan-400 focus:outline-none transition-colors"
                  placeholder="Entrez votre mot de passe"
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full bg-gradient-to-r from-cyan-500 to-cyan-400 text-black font-semibold py-2 md:py-3 px-4 rounded-md hover:from-cyan-400 hover:to-cyan-300 transition-all duration-300 transform hover:scale-[1.02] active:scale-[0.98] shadow-lg shadow-cyan-900/30"
                >
                  Se connecter
                </button>
              </div>
              {/* Error Message */}
              {errorMessage && (
                <div className="text-red-500 text-sm mt-2">
                  {errorMessage}
                </div>
              )}
            </form>
          </div>
        </div>
      </main>

      {/* Footer */}
      <footer className="bg-black border-t border-cyan-900/30 py-6 mt-auto">
        <div className="max-w-7xl mx-auto px-4 flex flex-col md:flex-row justify-between items-start">
          {/* Footer Left */}
          <div className="space-y-4 max-w-md">
            <img src={logo} alt="Logo" className="h-12" />
            <p className="text-gray-600">
              Revivez une expérience unique. Hibry vous fera revivre des moments inoubliables sur Minecraft. Accessible de la 1.8 à la 1.21.x, Hibry saura vous satisfaire avec de nombreux jeux amusants.
            </p>
          </div>
          {/* Footer Right */}
          <div className="text-right space-y-4 max-w-md mt-4 md:mt-0 hidden md:block">
            <h3 className="text-xl font-semibold tracking-wider text-white">LIENS UTILES</h3>
            <ul className="space-y-2 text-cyan-400">
              <li className="flex items-center">
                <span className="h-2 w-2 bg-cyan-400 rounded-full mr-2"></span>
                <a href="#" className="hover:text-cyan-300 transition-colors">SUPPORT</a>
              </li>
              <li className="flex items-center">
                <span className="h-2 w-2 bg-cyan-400 rounded-full mr-2"></span>
                <a href="#" className="hover:text-cyan-300 transition-colors">DISCORD</a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Connection;