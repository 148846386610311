// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Connection from './pages/Connection';
import ChangePrefix from './pages/ChangePrefix';
import Advantages from './pages/Advantages';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Connection />} />
        <Route path="/prefix" element={<ChangePrefix />} />
        <Route path="/advantages" element={<Advantages />} />
      </Routes>
    </Router>
  );
};

export default App;