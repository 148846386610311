import React, { useEffect, useState } from 'react';
import { Lock } from 'lucide-react';
import { MdHeadsetMic, MdMenu, MdClose } from "react-icons/md";
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/images/logo.png';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const Advantages = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [prefix, setPrefix] = useState("§4Admin");
  const [isValid, setIsValid] = useState(true);
  const [logoutAnimate, setLogoutAnimate] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  // Get the username from the cookies
  const username = Cookies.get('username');

  useEffect(() => {
    if (Cookies.get('username') === undefined) {
      navigate('/');
      return;
    }
    // Set the "lastpage" cookie to "Advantages"
    Cookies.set('lastpage', 'Advantages');
  }, [username, navigate]);

  useEffect(() => {
    // Simple validation for the prefix: must be non-empty and have a maximum length
    const isValidPrefix = prefix.length > 0 && prefix.length <= 16;
    setIsValid(isValidPrefix);
  }, [prefix]);

  useEffect(() => {
    const interval = setInterval(() => {
      const username = Cookies.get('username');
      if (!username) {
        navigate('/');
      }
    }, 5000); // Check every 5 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [navigate]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    setLogoutAnimate(true);
    setTimeout(() => {
      Cookies.remove('username');
      navigate('/');
    }, 1000); // Match the duration of the animation
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValid) {
      // Send the prefix to the server or perform another action
      console.log("Chosen prefix:", prefix);
    }
  };

  return (
    <div className="min-h-screen bg-black flex flex-col">
      <header className={`bg-gradient-to-r from-black via-black to-cyan-950 p-4 md:p-6 border-b border-cyan-900/30`} style={{ height: '100px' }}>
        <div className="max-w-6xl mx-auto flex justify-between items-center h-full relative">
          <div className="absolute left-0">
            <Link to="/">
              <img src={logo} alt="Logo" className="h-16 md:h-20" />
            </Link>
          </div>
          <div className="ml-auto flex items-center md:hidden">
            <button onClick={toggleMenu} className="text-cyan-500 focus:outline-none">
              {isOpen ? <MdClose className="h-8 w-8" /> : <MdMenu className="h-8 w-8" />}
            </button>
          </div>
          <nav className={`absolute left-1/2 transform -translate-x-1/2 space-x-4 md:space-x-6 hidden md:flex ${logoutAnimate ? 'navbar-fade-out' : ''}`}>
            <Link
              to="/prefix"
              className={`text-gray-400 hover:text-cyan-400 transition-colors ${location.pathname === '/prefix' ? 'text-cyan-400 border-b-2 border-cyan-400' : ''}`}
            >
              PRÉFIXE
            </Link>
            <Link
              to="/advantages"
              className={`text-gray-400 hover:text-cyan-400 transition-colors ${location.pathname === '/advantages' ? 'text-cyan-400 border-b-2 border-cyan-400' : ''}`}
            >
              AVANTAGES
            </Link>
            <Link
              to="/discord"
              className={`text-gray-400 hover:text-cyan-400 transition-colors ${location.pathname === '/discord' ? 'text-cyan-400 border-b-2 border-cyan-400' : ''}`}
            >
              DISCORD
            </Link>
          </nav>
          <div className={`ml-auto hidden md:flex items-center space-x-4 ${logoutAnimate ? 'navbar-fade-out' : ''}`}>
            {/* Support Button */}
            <div className="flex items-center border border-cyan-500 rounded-md p-2">
              <MdHeadsetMic className="text-cyan-500 h-6 w-6" />
              <Link to="/support" className="hover:text-cyan-300 transition-colors ml-2 font-semibold text-white">
                SUPPORT
              </Link>
            </div>

            {/* Profile and Logout */}
            <div className="flex items-center border border-cyan-500 rounded-md p-2">
              <img
                src={`https://mc-heads.net/avatar/${username}/64.png`}
                alt="Avatar"
                className="h-8 w-8 rounded-full mr-2"
              />
              <div className="flex flex-col items-start">
                <span className="text-white">{username}</span>
                <span className="text-white text-sm cursor-pointer hover:text-cyan-300 transition-colors mt-1" onClick={handleLogout}>
                  Déconnexion
                </span>
              </div>
            </div>
          </div>
        </div>
      </header>

      {isOpen && (
        <div className="bg-black/75 text-white p-4 absolute top-16 right-4 w-64 z-50 flex flex-col items-center justify-center space-y-4 rounded-md shadow-lg transition-opacity duration-300 ease-in-out opacity-100">
          {/* Profile and Logout */}
          <div className="flex flex-col items-center space-y-2">
            <span className="text-white">{username}</span>
            <img
              src={`https://mc-heads.net/avatar/${username}/64.png`}
              alt="Avatar"
              className="h-8 w-8 rounded-full"
            />
            <span className="text-white text-sm cursor-pointer hover:text-cyan-300 transition-colors" onClick={handleLogout}>
              Déconnexion
            </span>
          </div>
          <Link
            to="/prefix"
            className={`text-gray-400 hover:text-cyan-400 transition-colors text-xl ${location.pathname === '/prefix' ? 'text-cyan-400 border-b-2 border-cyan-400' : ''}`}
            onClick={toggleMenu}
          >
            PRÉFIXE
          </Link>
          <Link
            to="/advantages"
            className={`text-gray-400 hover:text-cyan-400 transition-colors text-xl ${location.pathname === '/advantages' ? 'text-cyan-400 border-b-2 border-cyan-400' : ''}`}
            onClick={toggleMenu}
          >
            AVANTAGES
          </Link>
          <Link
            to="/discord"
            className={`text-gray-400 hover:text-cyan-400 transition-colors text-xl ${location.pathname === '/discord' ? 'text-cyan-400 border-b-2 border-cyan-400' : ''}`}
            onClick={toggleMenu}
          >
            DISCORD
          </Link>
          <Link
            to="/support"
            className={`text-gray-400 hover:text-cyan-400 transition-colors text-xl ${location.pathname === '/support' ? 'text-cyan-400 border-b-2 border-cyan-400' : ''}`}
            onClick={toggleMenu}
          >
            SUPPORT
          </Link>
        </div>
      )}

      <main className="flex-1 flex items-center justify-center px-4 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-black via-black to-cyan-950">
        <div className="w-full max-w-md">
          <div className="bg-black/50 backdrop-blur-sm border border-cyan-900/30 rounded-lg shadow-2xl shadow-cyan-900/50 p-6 md:p-8">
            <div className="text-center mb-6 md:mb-8">
              <h1 className="text-2xl md:text-3xl font-bold text-white mb-2">CHANGER LE PRÉFIXE</h1>
              <p className="text-cyan-400">Le préfixe ne peut être changé qu'une fois tous les trois jours.</p>
            </div>

            <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
              <div>
                <label className="block text-gray-300 text-sm font-medium mb-2">Préfixe</label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Lock className="h-5 w-5 text-cyan-500" />
                  </div>
                  <input
                    type="text"
                    value={prefix}
                    onChange={(e) => setPrefix(e.target.value)}
                    className={`w-full bg-black/50 text-white pl-10 pr-4 py-2 md:py-3 rounded-md border ${isValid ? 'border-cyan-900/50' : 'border-red-500'} focus:border-cyan-400 focus:ring-1 focus:ring-cyan-400 focus:outline-none transition-colors`}
                    placeholder="Entrez le préfixe de votre choix"
                  />
                </div>
                {!isValid && <p className="text-red-500 text-xs mt-1">Le préfixe doit être entre 1 et 16 caractères.</p>}
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full bg-gradient-to-r from-cyan-500 to-cyan-400 text-black font-semibold py-2 md:py-3 px-4 rounded-md hover:from-cyan-400 hover:to-cyan-300 transition-all duration-300 transform hover:scale-[1.02] active:scale-[0.98] shadow-lg shadow-cyan-900/30"
                >
                  Choisir ce préfixe
                </button>
              </div>
            </form>
            <div className="text-center mt-4 text-cyan-400 text-sm">
              Aperçu du préfixe en jeu: 
            </div>
          </div>
        </div>
      </main>

      <footer className="bg-black border-t border-cyan-900/30 py-6 mt-auto">
        <div className="max-w-7xl mx-auto px-4 flex flex-col md:flex-row justify-between items-start">
          <div className="space-y-4 max-w-md">
            <img src={logo} alt="Logo" className="h-12" />
            <p className="text-gray-600">
              Revivez une expérience unique. Hibry vous fera revivre des moments inoubliables sur Minecraft. Accessible de la 1.8 à la 1.21.x, Hibry saura vous satisfaire avec de nombreux jeux amusants.
            </p>
          </div>
          <div className="text-right space-y-4 max-w-md mt-4 md:mt-0">
            <h3 className="text-xl font-semibold tracking-wider text-white">LIENS UTILES</h3>
            <ul className="space-y-2 text-cyan-400">
              <li className="flex items-center">
                <span className="h-2 w-2 bg-cyan-400 rounded-full mr-2"></span>
                <a href="#" className="hover:text-cyan-300 transition-colors">SUPPORT</a>
              </li>
              <li className="flex items-center">
                <span className="h-2 w-2 bg-cyan-400 rounded-full mr-2"></span>
                <a href="#" className="hover:text-cyan-300 transition-colors">DISCORD</a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Advantages;